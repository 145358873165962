.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*****************Mine***********************/

/*body {
    margin: 0;
}

main {
    margin: 16px;
}

.navbar {
    display: flex;
    align-items: center;
    background: #0d8448;
    color: white;
    font-family: Helvetica;
    font-weight: 300;
}

.navbar__title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
}

.navbar__item {
    padding: 16px 16px;
    cursor: pointer;
    vertical-align: middle;
}
.nav1{
    padding: 16px 16px; 
    cursor: pointer;
    vertical-align: middle;
    text-decoration-line: none;
    color: white;
}
.input-group-text .fa{
    padding: 0.95rem 0.75rem !important;
}*/
.error{
  color: red;
}
.dashboard-col{
  border-radius: 10px;
  height: 100px;
  margin: 10px 10px 10px 10px;
}
.white-text{
  color: #fff;
}
.dashboard-col:hover{
  opacity: 0.5 !important;
  cursor: pointer;
}
.user-img{
  background-color: #16b765;
  border-radius: 25px;
}
.dashboard-col:hover span.nav-text {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 60px;
  visibility: visible;
  transition: all 0.2s linear;
  width: 170px;
  height: 60px;
  background-color: #F1F1F1;
  color: #000;
}

.nav-text {
  position: absolute;
  visibility: hidden;
  width: 0px;
  font-family: 'Titillium Web', sans-serif;
}

.backBtn{
  background-color: #0d8448;
}

.InnerClass #header{
    background: #0d8448;
}

.InnerClass .newClass{
    margin-top: 8%;
}

/*.InnerClass .newClass1{
    margin-top: 10%;
}*/

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

/*#testStatus {
  position: relative;
  width: auto;
  height: 140px;
  left: 40px; }*/


/*
ul.testStatus {
  list-style: none; }

li.testStatus:first-child:after, li.testStatusGood:after, li.testStatusNoGood:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    
    border-bottom: 30px solid transparent;
    margin: -10px 90px 0 10px; 
}

li.m1:after{
  margin: -10px 90px 0 57px;
}

li.m3:after{
  margin: -10px 90px 0 36px;
}

li.testStatus:last-child:before, li.testStatusGood:before, li.testStatusNoGood:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    border-top: 80px solid transparent;    
    border-bottom: 80px solid transparent;
    margin: -10px 0px 0 0px; 
}

li.testStatus:first-child {
    padding-left: 10px;
    margin-left: 0;
}
li.testStatus:last-child {
    padding-right: 30px;
}
*/

/*li.testStatusGood:hover{
  opacity: 0.5 !important;
  cursor: pointer;
}*/
/*.topicDetail{
  text-align: left;
  width: 75%;
  margin-left: 15%;
}*/


  .mailbox .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-radius: 2px 2px 0 0;
  position: relative; }
  .mailbox .drop-title:after {
    content: '';
    position: absolute;
    /*background: url(../../assets/images/background/img5.png) no-repeat;*/
    opacity: 0.2;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-size: cover; }

.mailbox .nav-link {
  border-top: 1px solid #dee2e6;
  padding-top: 15px; }

.mailbox .message-center {
  overflow: auto;
  position: relative; }
  .mailbox .message-center .message-item {
    border-bottom: 1px solid #dee2e6 !important;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 9px 15px;
    cursor: pointer; }
    .mailbox .message-center .message-item:hover, .mailbox .message-center .message-item.active {
      background: #f6f9fc; }
    .mailbox .message-center .message-item:last-child {
      border: 0; }
    .mailbox .message-center .message-item .message-title {
      color: #455a64; }
    .mailbox .message-center .message-item .user-img {
      width: 40px;
      position: relative; }
      .mailbox .message-center .message-item .user-img img {
        width: 100%; }
      .mailbox .message-center .message-item .user-img .profile-status {
        border: 2px solid #fff;
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        left: 30px;
        position: absolute;
        top: 1px;
        width: 10px; }
      .mailbox .message-center .message-item .user-img .online {
        background: #22abbd; }
      .mailbox .message-center .message-item .user-img .busy {
        background: #fc4b6c; }
      .mailbox .message-center .message-item .user-img .away {
        background: #ffb22b; }
      .mailbox .message-center .message-item .user-img .offline {
        background: #ffb22b; }
    .mailbox .message-center .message-item .mail-contnet {
      width: 85%;
      vertical-align: middle;
      padding-left: 15px; }
      .mailbox .message-center .message-item .mail-contnet .message-title {
        margin: 5px 0px 0; }
      .mailbox .message-center .message-item .mail-contnet .mail-desc,
      .mailbox .message-center .message-item .mail-contnet .time {
        font-size: 12px;
        display: block;
        margin: 1px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #8898aa;
        white-space: nowrap; }

        .app-drawer {
          border: 1px solid #dee2e6;
        }
        /*******************
         Stickey inner-left-part
        *******************/
        .left-part {
          height: calc(100vh - 172px);
          width: 260px;
          position: absolute;
          overflow: hidden;
          border-right: 1px solid #dee2e6; }
          .left-part.fixed-left-part {
            position: fixed;
            top: 0px;
            padding-top: 10px; }
          .left-part .show-left-part {
            position: absolute;
            top: 45%;
            right: -41px; }

        .right-part {
/*          width: calc(100% - 260px);*/
          height: calc(100vh - 172px);
          overflow: auto;
          margin-left: 260px; }

        .reverse-mode .left-part {
          right: 0;
          border-left: 1px solid #dee2e6; }

        .reverse-mode .show-left-part {
          right: auto;
          left: -41px; }

        .reverse-mode .right-part {
          margin-left: 0px;
          margin-right: 260px; }


      .app-drawer.chat-room {
        background: #fff;
      }

      .app-drawer.chat-room .left-part {        
        width: auto;
        position: static;
        border:none;
      }

      .app-drawer.chat-room .right-part {
        margin-left:0;
      }

      .cl-search {
        background: #0d8448;
      }

      .cl-search .card-title {
        color:#fff;
      }
     
    
      
   
 /*.draggable {background-color:#00ff00;margin:5px;padding:3px;}*/
 /* #div1,#div2,#div3,#div4,#div5,#div6,#div7,#div8,#div9 {display:inline-block;min-width:25px;min-height:10px;border-style:solid;border-width:0px;border-bottom-width:2px;}*/

  /*#div11,#div12 {min-width:25px;min-height:10px;border-style:solid;border-width:0px;border-width:2px;} */

/*.testing{
  color: red;
  padding-left: 10px;
  padding-right: 10px;
}*/



/*.box::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
  border-radius: 5px
}

.box::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
  border-radius: 5px
}

.box::-webkit-scrollbar-thumb
{
  background-color: black;
  border: 2px solid black;
  border-radius: 5px
}*/

/*header{
  -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
  box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
  height: 110px;
  vertical-align: middle;
}*/

/*h1{
 float: left;
  padding: 10px 30px
}*/


.icons{
  display: inline;
  float: right
}

.notification{
  padding-top: 30px;
  position: relative;
  display: inline-block;
}

.number{
  height: 22px;
  width:  22px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 23px;
  left: 60px;
  padding: 3px;
  border-style: solid;
  border-width: 2px;
}

.number:empty {
   display: none;
}

.notBtn{
  transition: 0.5s;
  cursor: pointer
}

.fas{
  font-size: 25pt;
  padding-bottom: 10px;
  color: black;
  margin-right: 40px;
  margin-left: 40px;
}

/*.box{
  width: 400px;
  height: 0px;
  border-radius: 10px;
  transition: 0.5s;
  position: absolute;
  overflow-y: scroll;
  padding: 0px;
  left: -300px;
  margin-top: 5px;
  background-color: #F4F4F4;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
  box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
  cursor: context-menu;
}*/

.fas:hover {
  color: #d63031;
}

.notBtn:hover > .box{
  height: 60vh
}

.content{
  /*padding: 20px;*/
  color: black;
  vertical-align: middle;
  text-align: left;
}

.gry{
  background-color: #F4F4F4;
}

.top{
  color: black;
  padding: 10px
}

.display{
  position: relative;
}

.privacy .content p {
  /* padding: 20px; */
  color: black;
  vertical-align: middle;
  text-align: justify;
  /* display: flex; */
}




